<template>
  <div class="filter-item">
    <div>
      <v-select
          v-model="selectedFilterStatus"
          :label="$t('components.content-filter-expense-types.label')"
          :items="filterTypes"
          :item-text="getDisplayName"
          item-value="id"
          multiple
          chips
      />
    </div>
  </div>

</template>

<script>
import {HTTP} from "@/auth";

export default {
  name: "ExpenseTypeFilter",
  props: ['value'],
  data() {
    return {
      selectedFilterStatus: this.value,
      filterTypes: [],
    }
  },
  watch: {
    selectedFilterStatus: function (val) {
      this.$emit('input', val);
    }
  },
  async created() {
    let response = await HTTP.get('expense-types')
    this.filterTypes = response.data;
  },
  methods: {
    getDisplayName(item) {
      if (item.name) {
        return item.name
      }
      return this.$t('expenses.data.type.' + item.name_code)
    },
  },
}
</script>

<style scoped>

</style>
